import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Aside from '@/view/layout/aside';
import HistoryComponent from '@/view/layout/aside/historyComponent/history';
import Screenfull from '@/view/layout/screenfull';
import Search from '@/view/layout/search/search';
import BottomInfo from '@/view/layout/bottomInfo/bottomInfo';
import { mapGetters, mapActions } from 'vuex';
import CustomPic from '@/components/customPic';
export default {
  name: 'Layout',
  data: function data() {
    return {
      siteTitle: process.env.VUE_APP_SITE_TITLE,
      logoUrl: process.env.VUE_APP_LOGO_URL,
      show: false,
      isCollapse: false,
      isSider: true,
      isMobile: false,
      isShadowBg: false,
      loadingFlag: false,
      value: ''
    };
  },
  components: {
    Aside: Aside,
    HistoryComponent: HistoryComponent,
    Screenfull: Screenfull,
    Search: Search,
    BottomInfo: BottomInfo,
    CustomPic: CustomPic
  },
  methods: _objectSpread(_objectSpread({}, mapActions('user', ['LoginOut'])), {}, {
    totalCollapse: function totalCollapse() {
      this.isCollapse = !this.isCollapse;
      this.isSider = !this.isCollapse;
      this.isShadowBg = !this.isCollapse;
      this.$bus.emit('collapse', this.isCollapse);
    },
    toPerson: function toPerson() {
      this.$router.push({
        name: 'person'
      });
    },
    changeShadow: function changeShadow() {
      this.isShadowBg = !this.isShadowBg;
      this.isSider = !!this.isCollapse;
      this.totalCollapse();
    }
  }),
  computed: _objectSpread(_objectSpread({}, mapGetters('user', ['userInfo'])), {}, {
    title: function title() {
      return this.$route.meta.title || '当前页面';
    },
    matched: function matched() {
      return this.$route.matched;
    }
  }),
  mounted: function mounted() {
    var _this = this;

    var screenWidth = document.body.clientWidth;

    if (screenWidth < 1000) {
      this.isMobile = true;
      this.isSider = false;
      this.isCollapse = true;
    } else if (screenWidth >= 1000 && screenWidth < 1200) {
      this.isMobile = false;
      this.isSider = false;
      this.isCollapse = true;
    } else {
      this.isMobile = false;
      this.isSider = true;
      this.isCollapse = false;
    }

    this.$bus.emit('collapse', this.isCollapse);
    this.$bus.emit('mobile', this.isMobile);
    this.$bus.on("showLoading", function () {
      _this.loadingFlag = true;
    });
    this.$bus.on("closeLoading", function () {
      _this.loadingFlag = false;
    });

    window.onresize = function () {
      return function () {
        var screenWidth = document.body.clientWidth;

        if (screenWidth < 1000) {
          _this.isMobile = true;
          _this.isSider = false;
          _this.isCollapse = true;
        } else if (screenWidth >= 1000 && screenWidth < 1200) {
          _this.isMobile = false;
          _this.isSider = false;
          _this.isCollapse = true;
        } else {
          _this.isMobile = false;
          _this.isSider = true;
          _this.isCollapse = false;
        }

        _this.$bus.emit('collapse', _this.isCollapse);

        _this.$bus.emit('mobile', _this.isMobile);
      }();
    };
  }
};